const prod = {
    url: {
     API_URL: process.env.REACT_APP_BOT_URL,
     AIRTABLE: process.env.REACT_APP_AIRTABLE
    }
};

const dev = {
    url: {
     API_URL: `https://chatapi.ablejobs.co/app/connectedhchat`,
     AIRTABLE: `ConnectedH`
    }
};


export const config = process.env.NODE_ENV === 'development' ? dev : prod;
